var level_3_0_1 = {
    "0" : {
      "name": "Active Ageing Centre Service Model",
      "hash": "ActiveAgeingCentreServiceModel"
    },
    "1" : {
      "name": "Communities of Care (CoC)",
      "hash": "Coc"
    },
    "2" : {
      "name": "Industry Digital Plan (IDP)",
      "hash": "Idp"
    },
    "3" : {
      "name": "Sector Goals",
      "hash": "SectorGoals"
    }
};


var level_2_0 = {
  '0' : {
    name : 'Looking ahead',
    hash : 'LookingAhead',
    links : level_3_0_1 
  },
}

var level_1 = {
  '0' : {
    name: 'Building a stronger care community',
    sections: level_2_0,
    active: false
  },
}

export default level_1; 