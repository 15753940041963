<template>
    <div id="looking-ahead" :class='fontsize'>
    
		<Intro :intro="intro"/>

        <div class="container-fluid secondary-section fontsize" id="secondary-content">
            <div class="row">
             
              <SubNavOne :navLinks='navLinks'/>

                <section id='Navigation' class="content ml-auto ">

                    <Description/>                  

                    <ActiveAgeingCentreService/>

                    <Coc/>
                    
                    <Idp/>

                    <SectorGoals/>

                </section>

                <PageNavigation :prev="'/support-partners'" :next="'/one-aic-family'" />

            </div>
        </div>
    </div>  
</template>

<script>
// @ is an alias to /src
import SubNavOne from '@/components/SubNavOne.vue'
import NavLinks from '@/components/looking-ahead/navLinks.js'

import Intro from '@/components/Intro.vue'
import Description from '@/components/looking-ahead/Description.vue'
import ActiveAgeingCentreService from '@/components/looking-ahead/ActiveAgeingCentreService.vue'
import Coc from '@/components/looking-ahead/Coc.vue'
import Idp from '@/components/looking-ahead/Idp.vue'
import SectorGoals from '@/components/looking-ahead/SectorGoals.vue'
import PageNavigation from '@/components/PageNavigation.vue'

import dynamicFontSizeMixin from '@/mixin/dynamicFontSizeMixin'

export default {
        name: 'LookingAhead',

    data() {
        return {
            scroll: 0,
            sections: '',
            currentSec: '',
            navLinks : NavLinks,
            hashes: [],
            intro: {
                title: 'Building a stronger care community',
                secondTitle: 'Looking ahead',
                img: this.mobileAndTabletCheck() ? require("@/assets/looking-ahead/banner-mobile.jpg") : require("@/assets/looking-ahead/banner.jpg")
            }
        }
    },

    mixins: [dynamicFontSizeMixin],

    components: {
        SubNavOne,
        Intro,
        Description,
        ActiveAgeingCentreService,
        Coc,
        Idp,
        SectorGoals,
        PageNavigation,
    },
}
</script>

<style>
 /* media */
@media (min-width: 768px) {
	.content{
	 	width: 70vw;
	}
}
</style>