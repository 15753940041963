<template>
    <section id='SectorGoals' class="content-container" name='Sector Goals'>
        <div class="titles">                
            <h2>Sector Goals</h2>
        </div>
        <div class="col-12 secondary-image-container">
           <div class="img-line">
                <img src="@/assets/looking-ahead/image_4.jpg" alt="">
            </div>
            <p class="caption">One of the virtual focus group discussions with our colleagues from the sector</p>
        </div>
        <div class="col-12 px-3">
            <p>Over the past decade, together with like-minded partners, the Community Care sector has expanded its capacity and capability to support more seniors and their caregivers, while enhancing the quality of care delivered. 
            <p>COVID-19 has spotlighted opportunities in which the sector can transform to meet emerging needs and work closer to become stronger together.</p> 
            <p>As we look ahead towards the next decade, AIC aims to work with the sector to jointly develop a roadmap for our shared future, particularly in the areas of manpower, quality, productivity and digitalisation.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/looking-ahead/infograph_5.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>Sector-wide focus group discussions will be ongoing in 2021.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'SectorGoals',
}
</script>
