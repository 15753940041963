<template>
    <section id='Coc' class="content-container" name='Communities of Care (CoC)'>
        <div class="titles">                
            <h2>Communities of Care (CoC)</h2>
        </div>
        <div class="col-12 secondary-image-container">
           <div class="img-line">
                <img src="@/assets/looking-ahead/image_2.jpg" alt="">
                <p class="credits">(This photograph was taken prior to COVID-19)</p>
            </div>
            <p class="caption">Members of a CoC at a kick-off workgroup discussion in 2019</p>
        </div>
        <div class="col-12 px-3">
            <p>AIC is working with partners islandwide to create localised care communities to deliver seamless coordinated care. Under this pilot, each CoC is anchored on an Active Ageing Centre that aims to establish collaborations with hospitals, polyclinics, general practitioners and other Community Care providers within their community. With a shared purpose, understanding of each other’s role, and building of trust, the CoCs drive to better support our seniors to age in the community.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/looking-ahead/BAU Future_Infographics_2.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p><span class="stats">12</span> partners are currently driving CoCs in <span class="stats">14</span> sites islandwide.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Coc',
}
</script>
