<template>
    <section class="content-container row justify-content-center" id='description' name='Looking Ahead'>
        <div class="col-12 px-3">
            <p>Strong community support is key to ensuring that seniors can age with dignity. AIC’s vision for tomorrow is a vibrant care system with robust capabilities and networks.</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Description',
}
</script>
