<template>
    <section id='Idp' class="content-container" name='Industry Digital Plan (IDP)'>
        <div class="titles">                
            <h2>Industry Digital Plan (IDP)</h2>
        </div>
        <div class="col-12 secondary-image-container">
           <div class="img-line">
                <img src="@/assets/looking-ahead/image_3.jpg" alt="">
            </div>
            <p class="caption">The Health Marketplace is one of the digitalisation initiatives to transform the Community Care sector</p>
        </div>
        <div class="col-12 px-3">
            <p>The Community Care sector has grown tremendously over the past decade. The priorities are centered on increasing capacity, improving capabilities and enhancing service quality to meet the needs of clients. There is a need to focus on technology and digital solutions to deliver better care and improve productivity.</p>
            <p>AIC will be launching the IDP, a structured and targeted approach to support Community Care organisations in their digitalisation journey. They would conduct process redesigns and develop their own journeys to adopt technology, with the support of AIC. Through this, the IDP would facilitate the digital transformation of Community Care sector, resulting in improved productivity and enhanced staff capabilities to deliver better outcomes for clients.</p>
            <p id='HealthMarketplace'>An example of the digitalisation efforts is the Health Marketplace (HMP). It is a one-stop e-marketplace for clients and caregivers to access Community Care services. Today, HMP is available for the <a href="/support-seniors#SeniorsMobilityEnablingFund">Seniors’ Mobility & Enabling Fund (SMF)</a> Consumables and the <a href="/support-seniors#MedicalEscortTransport">Medical Escort & Transport (MET)</a> service.</p>
            <p>Clients and their caregivers can download the HMP consumer mobile app to place and track orders, as well as book trips directly with suppliers and service providers.</p>
            <p>Suppliers and service providers can use the HMP portal and provider app to support their daily operations.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row line">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/looking-ahead/infograph_3.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p class="line">The SMF consumables catalogue carries over <span class="stats">1,000</span> products with up to <span class="stats">25%</span> in savings for clients.
                        </p>
                        <p class="pt-2">
                            <span class="stats">$14 million</span> worth of goods have transacted, benefiting <span class="stats">3,800</span> SMF clients.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/looking-ahead/infograph_4.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>Over <span class="stats">5,800</span> MET trips have been made via the system.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Idp',
}
</script>
<style>
.line{
	border-bottom: 2px solid white;
}
</style>
<style scoped>
a {
    color: #DA3305;
}
a:hover {
    text-decoration: underline;
}
</style>
