<template>
    <section id='ActiveAgeingCentreServiceModel' class="content-container" name='Active Ageing Centre Service Model'>
        <div class="titles">                
            <h2>Active Ageing Centre Service Model</h2>
        </div>
        <div class="col-12 secondary-image-container">
           <div class="img-line">
                <img src="@/assets/looking-ahead/image_1.jpg" alt="">
            </div>
            <p class="caption">Centres will be better positioned to be hubs of care for seniors in the future</p>
        </div>
        <div class="col-12 px-3">
            <p>We are working with the Ministry of Health (MOH) to roll out the new eldercare centre service model, which comprises two types of centres, Active Ageing Centres and Active Ageing Care Hubs. At Active Ageing Centres, seniors can take part in active ageing activities, receive befriending support and get connected to care services and support schemes. Centres that also provide day care and rehabilitation services will be called Active Ageing Care Hubs. Both types of centres will serve all seniors regardless of income level and physical condition.</p>
        </div>
        <div class="col-12">
            <div class="table-diagram">
                <div class="row">
                    <div class="col-4 diagram-img">
                        <img src="@/assets/looking-ahead/infograph_1.png" alt="">
                    </div>
                    <div class="col-8 text-2">
                        <p>We envisage there to be over <span class="stats">200</span> of such eldercare centres by 2024.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'EldercareService',
}
</script>
